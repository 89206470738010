<!-- 最新更新 -->
<template>
  <div class="renewal">
    <!-- 导航标签 -->
    <div class="flex biao align-items"></div>
    <!-- 发布主题与搜素 -->
    <div class="flex space-between w-full fys align-items">
      <!-- 发布主题 -->
      <div class="btn">
        <el-button type="primary" @click="fabu">发表新帖</el-button>
      </div>
      <!-- 搜索 -->
      <div class="flex sousuo">
        <div class="kuang">
          <el-input
            v-model="input"
            placeholder="请输入您要查找的关键词"
            :maxlength="50"
          ></el-input>
        </div>
        <div class="btnOne">
          <el-button type="primary" @click="sousu">搜索</el-button>
        </div>
        <div class="btnTwo">
          <el-button type="info" plain @click="chongzhi">重置</el-button>
        </div>
      </div>
    </div>
    <!-- 帖子列表与榜单 -->
    <div class="flex space-between w-full">
      <!-- 左 -->
      <div class="w-full">
        <div class="biaoge">
          <!-- 头部 -->
          <div class="tab_head">
            <div class="flex align-items h-full">
              <div>{{ "排序 :" }}</div>
              <div class="flex redian h-full align-items">
                <div
                  class="kaung"
                  :class="{ act: btnNum == 1 }"
                  @click="reBtn(1)"
                >
                  <div>{{ "发帖时间" }}</div>
                  <div class="sanjiao"></div>
                </div>
                <div
                  class="kaung"
                  :class="{ act: btnNum == 0 }"
                  @click="reBtn(0)"
                >
                  <div>{{ "最新更新" }}</div>
                  <div class="sanjiao"></div>
                </div>
                <div
                  class="kaung"
                  :class="{ act: btnNum == 2 }"
                  @click="reBtn(2)"
                >
                  <div>{{ "热点" }}</div>
                  <div class="sanjiao"></div>
                </div>
                <div
                  class="kaung"
                  :class="{ act: btnNum == 3 }"
                  @click="reBtn(3)"
                >
                  <div>{{ "热评" }}</div>
                  <div class="sanjiao"></div>
                </div>
              </div>
            </div>
            <!-- 分类 已回复,待回复 -->
            <div
              class="flex align-items fenlie"
              v-if="$store.state.loginMessage.distinguish != 1"
            >
              <div class="flex align-items fenlie">
                <div>{{ "进度 : " }}</div>
                <div :class="{ actions: onePai == 0 }" class="mr20 cursor_pointer" @click="erjipai(0)">
                  {{ "全部" }}
                </div>
                <div :class="{ actions: onePai == 1 }" class="mr20 cursor_pointer" @click="erjipai(1)">
                  {{ "待受理" }}
                </div>
                <div :class="{ actions: onePai == 2 }" class="cursor_pointer mr20" @click="erjipai(2)">
                  {{ "已受理" }}
                </div>
                <div :class="{ actions: onePai == 3 }" class="cursor_pointer mr20" @click="erjipai(3)">
                  {{ "已回复" }}
                </div>
                <div :class="{ actions: onePai == 4 }" class="cursor_pointer" @click="erjipai(4)">
                  {{ "已办结" }}
                </div>
              </div>
            </div>
          </div>
          <el-empty description="" v-if="tieList.length == 0"></el-empty>
          <!-- 表身 -->
          <div class="tab_shen w-full" v-else>
            <!-- 模板 -->
            <div
              class="cardd"
              v-for="(item, index) in tieList"
              :key="index"
              @click="xiangqing(item.cardsalt)"
            >
              <div class="flex">
                <div class="imgT" v-if="tuchang(item.images) == 1">
                  <img
                    :src="item.images[0].di"
                    v-if="item.images[0].geshi == '图片'"
                    class="w-full h-full"
                    alt=""
                  />
                  <video
                    :src="item.images[0].di"
                    v-else
                    class="w-full h-full"
                  ></video>
                  <div class="bofang" v-if="item.images[0].geshi == '视频'">
                    <img
                      src="@/assets/img/xinban/bofang.png"
                      class="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
                <div class="flex1 sahngx">
                  <div class="flex biaoti align-items">
                    <div class="one">
                      <div
                        class="zi white-space"
                        v-if="item.type_txt == '咨询'"
                      >
                        【{{ "咨询" }}】
                      </div>
                      <div
                        class="hong white-space"
                        v-else-if="item.type_txt == '投诉'"
                      >
                        【{{ "投诉" }}】
                      </div>
                      <div
                        class="lv white-space"
                        v-else-if="item.type_txt == '建议'"
                      >
                        【{{ "建议" }}】
                      </div>
                    </div>
                    <div class="two white-space">
                      【
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.department_name"
                        placement="bottom"
                        :disabled="gf(item.department_name)"
                      >
                        <span> {{ buchandu(item.department_name) }}</span>
                      </el-tooltip>
                      】
                    </div>
                    <div class="flex flex1 align-items">
                      <!--  -->
                      <div class="three">{{ biaoti(item) }}</div>
                      <div class="four">
                        <img
                          src="@/assets/img/one/img1.png"
                          v-if="item.status == 0"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/img1.png"
                          v-else-if="item.status == 1"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/img1.png"
                          v-else-if="item.status == '待受理'"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/yishouli.png"
                          v-else-if="item.status == 2"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/yishouli.png"
                          v-else-if="item.status == '已受理'"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/yihuifu.png"
                          v-else-if="item.status == 3"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/yihuifu.png"
                          v-else-if="item.status == '已回复'"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/yihuifu.png"
                          v-else-if="item.status == 4"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/yihuifu.png"
                          v-else-if="item.status == '拟办结'"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/img2.png"
                          v-else-if="item.status == 5"
                          class="w-full h-full"
                          alt=""
                        />
                        <img
                          src="@/assets/img/one/img2.png"
                          v-else-if="item.status == '已办结'"
                          class="w-full h-full"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="nerong" v-if="tuchang(item.images) == 0"></div>
                  <div class="nerong" v-if="tuchang(item.images) > 1">
                    <div
                      class="imgT"
                      v-for="(item, index) in item.images"
                      :key="index"
                    >
                      <img
                        :src="item.di"
                        v-if="item.geshi == '图片'"
                        class="w-full"
                        alt=""
                      />
                      <video
                        :src="item.di"
                        v-else
                        class="w-full h-full"
                      ></video>
                      <div class="bofang" v-if="item.geshi == '视频'">
                        <img
                          src="@/assets/img/xinban/bofang.png"
                          class="w-full"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <!-- 作者点赞 -->
                  <div class="zuozhe flex w-full space-between align-items">
                    <div class="flex">
                      <div class="up flex align-items">
                        <img
                          src="@/assets/img/xinban/times.png"
                          class="shijianl"
                          alt=""
                        />
                        <!-- btnNum == 0 ? item.updatetime : item.articletime -->
                        <div>
                          {{ item.articletime2 }}
                        </div>
                      </div>
                      <div>{{ item.name }}</div>
                    </div>
                    <div class="flex">
                      <!-- 收藏 -->
                      <div class="flex ml23">
                        <div class="icon">
                          <img
                            src="@/assets/img/xinban/shoucang.png"
                            v-if="item.collectiontag == 0"
                            class="w-full h-full"
                            alt=""
                          />
                          <img
                            src="@/assets/img/one/yishoucnag.png"
                            v-else
                            class="w-full h-full"
                            alt=""
                          />
                        </div>
                        <div>{{ item.collections }}</div>
                      </div>
                      <div class="flex ml23">
                        <div class="icon">
                          <img
                            src="@/assets/img/xinban/yulang.png"
                            class="w-full h-full"
                            alt=""
                          />
                        </div>
                        <div>{{ item.views }}</div>
                      </div>
                      <div class="flex ml23">
                        <div class="icon">
                          <img
                            src="@/assets/img/xinban/pinglun.png"
                            class="w-full h-full"
                            alt=""
                          />
                        </div>
                        <div>{{ item.comments }}</div>
                      </div>
                      <div class="flex ml23">
                        <div class="icon">
                          <img
                            src="@/assets/img/xinban/dainzan.png"
                            v-if="item.liketag == 0"
                            class="w-full h-full"
                            alt=""
                          />
                          <img
                            src="@/assets/img/one/img19.png"
                            v-else
                            class="w-full h-full"
                            alt=""
                          />
                        </div>
                        <div>{{ item.likes }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 页码 -->
        <div style="min-height: 30px">
          <div class="yema" v-show="total > 15">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              :page-sizes="[15, 30]"
              :page-size="100"
              layout=" prev, pager, next, sizes, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/list.js";
export default {
  data() {
    return {
      input: "", //搜索框
      btnNum: 1,
      total: 0,
      tieList: [], //帖子列表
      onePai: 0, //二级排序
      loading: null, //等待效果
      page: 1,
      zongye: 15,
      dangqian: 1, //一级排序
      techa: "", //特殊类型查询
      zhoubang: [], //周榜
      yuebang: [], //月棒
      zongbang: [], //总帮
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "待受理",
        },
        {
          value: "2",
          label: "已受理",
        },
        {
          value: "3",
          label: "已回复",
        },
        {
          value: "4",
          label: "已办结",
        },
      ],
      value: "",
      types: 3,
    };
  },
  mounted() {
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
    localStorage.setItem("path", this.$route.path);

    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.onePai = this.$store.state.classifNum;
    this.types = this.$store.state.loginMessage.listkey;
    // this.dangqian = this.$store.state.loginMessage.KindNum;
    this.dangqian = 1;
    if (this.dangqian == undefined) {
      this.dangqian = 1;
    }
    this.articleCardList(this.dangqian, this.onePai, 0);
  },
  methods: {
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length > 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchandu(str) {
      let a = str.split(",");
      if (a.length <= 2) {
        return a.toString();
      } else {
        return a[0] + "," + a[1] + "...";
      }
    },
    // 三级怕徐
    sanji(num) {
      this.articleCardList(this.dangqian, this.onePai, num);
    },
    // 按状态查询
    dy() {
      this.articleCardList(this.dangqian, this.value, this.types);
    },
    tuchang(obj) {
      if (obj == null) {
        return 0;
      }
      if (obj == "") {
        return 0;
      }
      return obj.length;
    },
    // 重置
    chongzhi() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.input = "";
      this.btnNum = 0;
      this.page = 1;
      this.articleCardList(0, 0, this.types);
    },
    // 跳转至详情
    xiangqing(id) {
      this.jumpDel(this.types);
      this.$store.state.loginMessage.cardsalt = id;
      this.$store.state.loginMessage.price = 1;
      this.jump("details", this.$store.state.loginMessage);
    },
    // 搜素内容
    async sousu() {
      this.page = 1;
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.articleCardList(this.dangqian, this.onePai, this.types);
    },
    // 首图展示
    biaoti(obj) {
      if (obj.images == null) {
        if (obj.title.length < 51) {
          return obj.title;
        } else {
          return obj.title.substr(0, 45) + "...";
        }
      }
      if (obj.images == "") {
        if (obj.title.length < 51) {
          return obj.title;
        } else {
          return obj.title.substr(0, 45) + "...";
        }
      }
      if (obj.images.length == 1) {
        if (obj.title.length < 30) {
          return obj.title;
        } else {
          return obj.title.substr(0, 30) + "...";
        }
      }
      if (obj.title.length < 51) {
        return obj.title;
      } else {
        return obj.title.substr(0, 45) + "...";
      }
    },
    // 进入发布页面
    fabu() {
      this.jumpDel(); 
      // this.jumps("issue", this.$store.state.loginMessage);
      this.$router.push("/issue");
    },
    // 热点按钮样式
    reBtn(num) {
       // 不允许重复点击
       if (this.dangqian == num) {
        return;
      }
      this.btnNum = num;
      this.total = 0;
      this.page = 1;
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.articleCardList(num, this.onePai, this.types);
    },
    // 二级排序
    erjipai(num) {
       // 不允许重复点击
       if (this.onePai == num) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.page = 1;
      this.articleCardList(this.dangqian, num, this.types);
    },
    // 帖子列表
    // kindeNum : 类型:0=最新更新,1=编号,2=热点,3=热评
    // classifNum : 类别:0=无,1=待回复,2=已回复
    // type	类别:0=咨询,1=投诉,2=建议,3=全部
    async articleCardList(KindNum, classifNum, types) {
      this.dangqian = KindNum;
      this.onePai = classifNum;
      this.types = types;
      let res = await API.articleCardList({
        sinfo: this.input,
        kind: KindNum,
        classif: classifNum,
        type: types,
        artstime: 0,
        departid: "",
        genres: 0,
        page: this.page,
        pageLine: this.zongye,
      }).then((res) => {
        if (res.code == 1) {
          this.tieList = res.data.list;
          this.total = res.data.total;
        }
      });
      this.loading.close();
    },
    // 切换条目
    handleSizeChange(val) {
      this.zongye = val;
      this.articleCardList(this.dangqian, this.onePai, this.types);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val;
      this.articleCardList(this.dangqian, this.onePai, this.types);
      window.scrollTo(0, 0);
    },
  },
  beforeDestroy() {
    this.$store.state.chatype = "";
    this.$store.state.danweiid = "";
  },
};
</script>

<style scoped lang="scss">
.renewal {
  width: 1200px;
  height: 100%;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 22px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

// 发布与搜索
.fys {
  margin-bottom: 14px;

  .btn {
    ::v-deep(.el-button) {
      width: 110px;
      height: 40px;
      background: #2670c5;
      border-radius: 2px;
      font-family: PingFangSC-Medium;
      font-size: 15px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 500;
      border: 0px solid red;
    }
  }

  .sousuo {
    .kuang {
      ::v-deep(.el-input__inner) {
        width: 361px;
        height: 44px;
        background: #ffffff;
        border: 1px solid #d6dbe3;

        &::-webkit-input-placeholder {
          font-family: PingFangSC-Regular;
          font-size: 15px;
          color: #484c51;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }

    .btnOne {
      margin-left: 14px;

      ::v-deep(.el-button) {
        width: 66px;
        height: 44px;
        background: #2670c5;
        border-radius: 2px;
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
        border: 0px solid red;
      }
    }

    .btnTwo {
      margin-left: 14px;

      ::v-deep(.el-button) {
        width: 66px;
        height: 44px;
        background: #86898d;
        border-radius: 2px;
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
        border: 0px solid red;
      }
    }
  }
}

.biaoge {
  // width: 865px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d6dbe3;

  .fenlie {
    .mr20 {
      margin-left: 10px;
      margin-right: 20px;
    }

    :deep(.el-input__inner) {
      width: 140px;
      height: 44px;
      border: 0px solid red;
      background: transparent;

      &::-webkit-input-placeholder {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }

  .tab_head {
    width: 100%;
    height: 57px;
    display: flex;
    align-items: center;
    background: #e8effa;
    padding: 0 23px 0 22px;
    justify-content: space-between;

    .redian {
      margin-left: 10px;
    }

    .kaung {
      width: 96px;
      height: 100%;
      font-family: PingFangSC-Medium;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .sanjiao {
        position: absolute;
        bottom: 0;
        width: 40px;
        height: 10px;
        left: calc(50% - 20px);
        background: transparent;
        border-style: solid;
        border-width: 0 20px 10px 20px;
        border-color: transparent transparent #e8effa transparent;
      }
    }

    .xuanxian {
      width: 160px;
      justify-content: space-between;

      .one {
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #2b317f;
        letter-spacing: 0;
        font-weight: 500;
      }

      .two {
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #f7593b;
        letter-spacing: 0;
        font-weight: 500;
      }

      .three {
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #00a576;
        letter-spacing: 0;
        font-weight: 500;
      }
    }

    .gengduo {
      width: 20px;
      height: 20px;
    }

    .fontFive {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #0653ac;
      letter-spacing: 0;
      font-weight: 400;
      margin-right: 5px;
    }
  }

  // 表身
  .tab_shen {
    // padding: 0px 17px 10px 15px;

    .cardd {
      width: 100%;
      border-bottom: 1px solid #d6dbe3;
      // padding: 18px 0 17px 0;
      padding: 18px 17px 10px 15px;

      &:hover {
        cursor: pointer;
        background: #a0d1f9;
      }

      .imgT {
        width: 180px;
        height: 135px;
        margin-right: 21px;
        background: #000;
        position: relative;
        border: 1px solid #eeeef1;
        overflow: hidden;
        img:hover {
          transform: scale(1.3);
          transition: all 0.5s ease-out 0.1s;
        }
      }

      .biaoti {
        margin-bottom: 10px;

        .one {
          font-family: PingFangSC-Medium;
          font-size: 18px;
          letter-spacing: 0;
          // line-height: 25px;
          font-weight: 700;

          .zi {
            color: #34348f;
          }

          .lv {
            color: #00a576;
          }

          .hong {
            color: #ff5637;
          }
        }

        .two {
          font-family: PingFangSC-Medium;
          font-size: 18px;
          color: #18191a;
          letter-spacing: 0;
          // line-height: 25px;
          font-weight: 700;
        }

        .three {
          font-family: PingFangSC-Medium;
          font-size: 18px;
          color: #18191a;
          letter-spacing: 0;
          // line-height: 25px;
          font-weight: 700;
          white-space: nowrap;
          margin-right: 10px;
        }

        .four {
          width: 61px;
          height: 19.7px;
        }
      }

      .nerong {
        width: 100%;
        min-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #191a1c;
        letter-spacing: 0;
        font-weight: 400;
        display: flex;
        padding: 0 10px;

        .imgT {
          width: 220px;
          height: 135px;
          margin-right: 2%;
          background: #000;
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 10px;
          position: relative;

          .bofang {
            position: absolute;
            width: 50px;
            height: 50px;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
            border-radius: 50%;
            overflow: hidden;
          }
        }
      }

      .zuozhe {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #484c51;
        letter-spacing: 0;
        // text-align: justify;
        // line-height: 21px;
        font-weight: 400;

        .up {
          margin-right: 36px;
          margin-left: 10px;
        }

        .ml23 {
          margin-left: 23px;
        }
      }

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 3px;
      }
    }
  }
}

.bangdan {
  width: 321px;

  .bang {
    width: 100%;
    height: 464px;
    background: #ffffff;
    border: 1px solid #d6dbe3;
    margin-bottom: 18px;
    padding: 17px 12px 8px 17px;
    display: flex;
    flex-direction: column;
  }

  .tubiao {
    width: 6px;
    height: 20px;
    background: #2670c5;
    border-radius: 4px;
    box-shadow: 3px 2px 0px rgba(38, 75, 197, 0.19);
    margin: 0 10.33px 0 0;
  }

  .fontOne {
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #005293;
    letter-spacing: 0;
    font-weight: 600;
  }

  .shangbang {
    flex: 1;
    width: 100%;
    padding: 10px 0 0 0;
    display: flex;
    flex-direction: column;
  }

  .fangk {
    width: 16px;
    height: 16px;
    background: #8f96a5;
    border-radius: 1px;
    line-height: 16px;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 600;
    margin-right: 6px;
  }

  .xiaofang {
    width: 4px;
    height: 4px;
    background: #2670c5;
    margin-right: 15px;
  }

  .timu {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }

  .fir {
    background: #f11515;
  }

  .tw {
    background: #fa8c2f;
  }

  .th {
    background: #2670c5;
  }
}

.yema {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: flex-end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
}

.act {
  background: #2670c5 !important;
  font-family: PingFangSC-Medium;
  font-size: 15px !important;
  color: #ffffff !important;
  letter-spacing: 0;
  font-weight: 600;
}

.bofang {
  position: absolute;
  width: 30px;
  height: 30px;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  border-radius: 50%;
  overflow: hidden;
}

.noneBor {
  border-bottom: 0px solid red !important;
}

.mt18 {
  margin-bottom: 18px;
}
.shijianl {
  width: 14.5px;
  height: 14.5px;
  margin-right: 6.5px;
}
.sahngx {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.actions {
  padding: 8px 10px;
  box-sizing: border-box;
  background: #3eacdd !important;
  font-size: 15px !important;
  color: #fff !important;
  text-align: center;
  border-radius: 8px;
}
</style>